export const focusAndClickInputByName = (name: string): void => {
  const inputElement: HTMLInputElement | null = document.querySelector(
    `input[type="text"][name="${name}"]`,
  );

  if (inputElement) {
    inputElement.focus();
    inputElement.click();
  }
};
