import React from 'react';
import { ImpressaoDeEtiquetasProvider } from './ImpressaoDeEtiquetasContext';
import ImpressaoDeEtiquetasContent from './ImpressaoDeEtiquetasContent';

const ImpressaoDeEtiquetas: React.FC = () => {
  function defaultOnlyReport() {
    return false;
  }
  return (
    <ImpressaoDeEtiquetasProvider>
      <ImpressaoDeEtiquetasContent />
    </ImpressaoDeEtiquetasProvider>
  );
};

export default ImpressaoDeEtiquetas;
